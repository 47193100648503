// $primary: #a51616;
@import url('https://fonts.googleapis.com/css?family=Dosis:300,400,700');

$primary:#fbe939; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #1e2123; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}
.navbar-default{
	background: $blk;
	border: none;
}
nav {
	z-index: 1000;
	background: $blk;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 100px;
		display: flex;
		align-items: center;


		@media (max-width: 767px) {
		    height: 22px;
		    display: inline-block;
				line-height: 0;
		}

	    &:hover, &:focus {
	    	background: darken($primary, 30%);
	    	color: lighten($primary, 50%);
				transition: all 0.7s ease 0s;


	    }
	}
}

.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 388px) {
		margin-top: 14px;
	}
}
.cta {
	padding: 15px 20px;
	background: $blk;
	color: $wht;
	font-size: 1.2em;
	margin: 5px;
	&:hover{
		border-radius: 0px;
		color: $wht;
		background: darken($primary, 40%);
		transition: all 0.7s ease 0s;

	}
}
.logo{
	@media(max-width: 767px){
		max-height: 70px;
	}
}
h1, h2, h3, p, a  {
		font-family: 'Dosis', sans-serif;
		font-size: 1.5em;
}
h1 {
	font-family: 'Dosis', sans-serif;
	font-weight: bold;
	color: $primary;
	font-size: 3em;
	text-transform: uppercase;
	@media (max-width: 767px){
		font-size: 2em;
	}
}

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $blk;
	border-radius: $border-radius;
	background: $blk;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($blk,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $blk;
		border: none;
	}
}

.modal-dialog {
	max-width: 250px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $blk;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($blk,20%);
		text-align: center;
		color: $blk;
	}

	button {
		background: $blk;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header, .modal-footer {
	background:$blk;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: darken($blk, 3%);
	color: $footerLinks;
	p {
		font-size: 1em;
	}
	a {
		font-size: 1.2em;
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

section {
	background-size: cover;
	background-repeat: no-repeat;;
	background-position:  center;
}

.hero-banner {
	background-image:url('../img/bg1.jpg');
	padding: 350px 0;
	p {
		font-family: 'Dosis', sans-serif;
		font-style: italic;
		font-size: 2em;
		color: darken($wht, 15%);
	}
	@media (max-width: 1024px){
		background-image:url('../img/bg1-mb.jpg');
		padding: 150px 0;
	}
	@media (max-width: 767px){
		padding: 100px 0;
	}
}

.trio-section {
	background-color:darken($primary, 15%);
	color: $blk;
	padding: 50px 0;
	h1 {
		color:$blk;
		text-transform: capitalize;
		padding-bottom: 50px;
	}
	h3 {
		font-size: 2em;
		padding-bottom: 25px
	}
	.cta {
		background: $blk;
		&:hover{
			color: $wht;
			background: darken($primary,60%);
			transition: all 0.7s ease 0s;

		}
	}
	@media(max-width: 767px){
		padding: 80px 0;
	}
}
.form {
	background: $blk;
	padding: 20px 0;
}
.box-info {
	background-image: url('../img/lastbox.jpg');
	padding:350px 0;
	@media(max-width: 767px){
		padding: 100px 0 100px 0;
	}
	@media(min-width: 768px) and (max-width: 1024px){
		padding: 100px 0 100px 0;
	}
	h1 {
		color: $primary;
		font-size: 4em;
		@media (max-width: 767px){
			font-size: 2em
		}
	}
	h2 {
		color: $blk;
		padding: 45px 0;
	}
	h3 {
		font-size: 3em;
		font-family: 'Dancing Script', cursive;
		color: darken($primary, 20%);
		padding-bottom: 35px;
	}
	p {
		font-size: 1.5em;
		margin-top: 25px;
	}

}
